import React from "react"
import { graphql } from "gatsby"
import { Flex } from "rebass"
import Layout from "../components/layout"
import rehypeReact from "rehype-react"
import SEO from "../components/seo"
import { Section, Para } from "../components/basic"
import styled from "styled-components"
import { breakpoints } from "../theme"

const Text = styled("div")`
  font-size: 20px;
  font-weight: 300;
`
const Title = styled("div")`
  font-size: 34px;
  line-height: 48px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #525252;
  @media (max-width: ${breakpoints[0]}) {
    font-size: 28px;
    text-align: center;
  }
`
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { p: Para },
}).Compiler

export default ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, htmlAst, excerpt } = markdownRemark
  return (
    <Layout hideHeader>
      <SEO title={`${frontmatter.title} | ANMC`} description={excerpt} />
      <Section>
        <Flex pt={10} px={[6, 6, 9, 9]} flexDirection="column">
          <Title>{frontmatter.title}</Title>
        </Flex>
        <Flex px={[6, 6, 9, 9]} py={8}>
          <Text>{renderAst(htmlAst)}</Text>
        </Flex>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query policyDetailsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerpt
      frontmatter {
        title
      }
    }
  }
`
